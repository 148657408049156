<template>
    <form @submit.prevent="createTime">
        <div>
            <label class="block mb-1" for="from">From</label>
            <Calendar id="calendar-timeonly" class="w-full" v-model="from" timeOnly />
        </div>
        <div class="mt-3">
            <label class="block mb-1" for="to">To</label>
            <Calendar id="calendar-timeonly" class="w-full" v-model="to" timeOnly />
            <p v-if="errors.name" class="text-sm text-danger">Please fill the required fields.</p>          
        </div>
        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">{{ $t('actions.create') }}</button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits} from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';
//prime vue components
import Calendar from 'primevue/calendar';

const emit = defineEmits(['created']);

const from = ref("");
const to = ref("");
const errors = ref({});

const convertDate = (dateString) => {
    const time = new Date(dateString);
    const format = time.getHours();
    return format < 10 ? `0${format}` : format.toString();
}

const createTime = () => {
    const id = toast.loading('Creating, please wait . . .');
    let start = convertDate(from.value)
    let end = convertDate(to.value)
    let formData = {
        name : `${start}-${end}`
    }
    ApiService.post('delivery-accept-times' , formData).then(() => {
        toast.update(id, {
            render: "Created accept time.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('created');
    }).catch((res) => {
        toast.update(id, {
            render: "Error creating accept time.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}
</script>

<style scoped>

</style>