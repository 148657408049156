import ApiService from "./ApiService"

export const getStates = async (page = 1 , limit = "") => {
    const res = await ApiService.get(`states?page=${page}&limit=${limit}`);
    return res;
}

export const getAcceptTimes = async (page = 1, limit = "") => {
    const res = await ApiService.get(`delivery-accept-times?page=${page}&limit=${limit}`);
    return res;
}

export const getDeliveryAgents = async (page = 1, limit = "") => {
    const res = await ApiService.get(`delivery-agents?page=${page}&limit=${limit}`);
    return res;
}

export const getPaymentMethods = async (page = 1, limit = "") => {
    const res = await ApiService.get(`payment-methods?page=${page}&limit=${limit}`);
    return res;
}

export const getProducts = async (page = 1, limit = "") => {
    const res = await ApiService.get(`products?page=${page}&limit=${limit}`);
    return res;
}

export const getDeliveryTypes = async (page = 1, limit = "") => {
    const res = await ApiService.get(`delivery-types?page=${page}&limit=${limit}`);
    return res;
}

export const getSources = async (page = 1, limit = "") => {
    const res = await ApiService.get(`sources?page=${page}&limit=${limit}`);
    return res;
}

export const getCategories = async (page = 1, limit = "") => {
    const res = await ApiService.get(`categories?page=${page}&limit=${limit}`);
    return res;
}

export const getSubCategories = async (page = 1, limit = "") => {
    const res = await ApiService.get(`sub-categories?page=${page}&limit=${limit}`);
    return res;
}

export const getStatuses = async (page = 1, limit = "") => {
    const res = await ApiService.get(`statuses?page=${page}&limit=${limit}`);
    return res;
}

export default {
    getStates,
    getAcceptTimes,
    getDeliveryAgents,
    getPaymentMethods,
    getProducts,
    getDeliveryTypes,
    getSources,
    getCategories,
    getSubCategories,
    getStatuses
}