<template>
    <div v-if="loading">
        <LoadingIndi />
    </div>
    <div v-else class="p-2 pb-12 text-gray-700">
        <div class="flex items-center justify-between w-full gap-3 mx-auto lg:w-5/6">
            <div class="flex items-center">
                <router-link :to="{name : 'AffiliateOrders' , query : {page : 1 , perPage : 10}}" class="mt-1">
                    <span class="material-icons-round">west</span> 
                </router-link>
                <h1 class="ml-2 text-2xl">{{ $t('titles.order_details') }}</h1>
            </div>
            <button @click="copyOrder" class="inline-flex items-center px-6 py-2 text-white rounded-md hover:bg-success/80 bg-success">
                <span class="material-icons-outlined">content_copy</span>
                <span class="ml-2 font-bold">{{ $t('actions.copy_order') }}</span>
            </button>
        </div>
        <div class="flex justify-end w-full gap-3 mx-auto mt-4 lg:w-5/6">
            <button @click="showLogs = true" class="underline text-primary">
                {{ $t('actions.show_logs') }}
            </button>
        </div>

        <!-- logs -->
        <Dialog v-model:visible="showLogs" modal header="Order Logs" :style="{ width: '60rem' }">
            <OrderLogs :creator="order?.user?.name" :created_at="order.created_at" :order_id="order.id" />
        </Dialog>

        <!-- Basic INFO -->
        <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
            <h1 class="text-[1.35rem] font-semibold">{{ $t('titles.basic_info') }}</h1>
            <button v-if="!editBasics" @click="editBasics = true" class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary">
                <span style="margin-right: 10px;font-size: 1.2rem;" class="material-icons-round">edit</span>
                {{ $t('actions.edit') }}
            </button>
        </div>
        <form @submit.prevent="updateBasicsInfo" class="w-full mx-auto mb-6 lg:w-5/6">
            <div class="grid gap-4 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1 text-label" for="name">{{ $t('labels.name') }}</label>
                    <InputText
                        v-model="basicInfo.name"
                        name="name"
                        required
                        type="text"
                        placeholder="Jhon Doe"
                        :disabled="!editBasics"
                        class="w-full disabled:bg-white"
                     />      
                     <p v-if="errors.name" class="text-sm text-danger">{{ errors.name[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1 text-label" for="email">Email</label>
                    <InputText
                        v-model="basicInfo.email"
                        name="email"
                        required
                        type="text"
                        placeholder="-"
                        :disabled="!editBasics"
                        class="w-full disabled:bg-white"
                     />        
                     <p v-if="errors.email" class="text-sm text-danger">{{ errors.email[0] }}</p>                  
                </div>
                <div>
                    <label class="block mb-1" for="phone_no">Phone number</label>
                    <InputText
                        v-model="basicInfo.phone_no"
                        name="phone_no"
                        type="text"
                        placeholder="-"
                        :disabled="!editBasics"
                        class="w-full disabled:bg-white"
                     />
                     <p v-if="errors.phone_no" class="text-sm text-danger">{{ errors.phone_no[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1" for="fb_link">Facebook Link</label>
                    <InputText
                        v-model="basicInfo.fb_link"
                        name="fb_link"
                        type="text"
                        placeholder="-"
                        :disabled="!editBasics"
                        class="w-full disabled:bg-white"
                     />    
                     <p v-if="errors.fb_link" class="text-sm text-danger">{{ errors.fb_link[0] }}</p>                      
                </div>
                <div class="col-start-1">
                    <label class="block mb-1 text-label" for="postal_code">Postal Code</label>
                    <InputText
                        v-model="basicInfo.postal_code"
                        name="postal_code"
                        type="text"
                        @change="checkPostalCode"
                        :disabled="!editBasics"
                        placeholder="09123456789"
                        class="w-full disabled:bg-white"
                     />
                     <p v-if="errors.postal_code" class="text-sm text-danger">{{ errors.postal_code[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1 text-label" for="state_id">State</label>
                    <Dropdown
                        v-model="basicInfo.state_id"
                        :options="states"
                        option-label="name"
                        option-value="id"
                        filter
                        optionLabel="name"
                        placeholder="Select a state"
                        :disabled="!editBasics"
                        class="w-full bg-white" />        
                        <p v-if="errors.state_id" class="text-sm text-danger">{{ errors.state_id[0] }}</p>               
                </div>
                <div>
                    <label for="address" class="block mb-1 text-label">Address</label>
                    <Textarea
                        v-model="basicInfo.address"
                        placeholder="enter full address"
                        name="address"
                        :disabled="!editBasics"
                        rows="1"
                        cols="30"
                        class="w-full disabled:bg-white"
                    />
                    <p v-if="errors.address" class="text-sm text-danger">{{ errors.address[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1" for="apartment_name">Apartment Name</label>
                    <InputText
                        v-model="basicInfo.apartment_name"
                        name="apartment_name"
                        type="text"
                        placeholder="(Optional)"
                        :disabled="!editBasics"
                        class="w-full bg-white"
                        />
                        <p v-if="errors.apartment_name" class="text-sm text-danger">{{ errors.apartment_name[0] }}</p>                      
                </div>
            </div>
            <div v-if="editBasics" class="flex items-center justify-end mt-4 mb-8">
                <span @click="cancelEditBasics" class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary">{{ $t('actions.cancel') }}</span>
                <button class="px-6 py-2 ml-3 text-white rounded bg-secondary">{{ $t('actions.update') }}</button>
            </div>
        </form>

        <!-- Delivery INFO -->
        <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
            <h1 class="text-[1.35rem] font-semibold">{{ $t('titles.deli_info') }}</h1>
        </div>
        <div  class="w-full mx-auto lg:w-5/6">
            <div class="grid gap-4 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="delivery_accept_time_id">Delivery accept time</label>
                    <Dropdown
                        v-model="deliveryInfos.delivery_accept_time_id"
                        :options="acceptTimes"
                        option-label="name"
                        option-value="id"
                        optionLabel="name"
                        filter
                        placeholder="-"
                        :disabled="!editDelivery"
                        class="w-full bg-white" />      
                        <p v-if="errors.delivery_accept_time_id" class="text-sm text-danger">{{ errors.delivery_accept_time_id[0] }}</p>                 
                </div>
                <div>
                    <label class="block mb-1" for="delivery_accept_date">Delivery accept date</label>
                    <Calendar
                        v-model="deliveryInfos.delivery_accept_date"
                        showButtonBar
                        placeholder="-"
                        :disabled="!editDelivery"
                        class="w-full bg-white disabled:bg-white"
                    />   
                    <p v-if="errors.delivery_accept_date" class="text-sm text-danger">{{ errors.delivery_accept_date[0] }}</p>          
                </div>
            </div>
        </div>

        <!-- activateOn -->
        <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
            <h1 class="text-[1.35rem] font-semibold">{{ $t('titles.activate_on') }}</h1>
            <button v-if="!editActivateOn" @click="editActivateOn = true" class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary">
                <span style="margin-right: 10px;font-size: 1.2rem;" class="material-icons-round">edit</span>
                {{ $t('actions.edit') }}
            </button>
        </div>
        <form @submit.prevent="updateActivateOn" class="mx-auto mt-4 lg:w-5/6">
                <label class="block mb-1 text-sm">{{ $t('titles.activate_on') }}</label>
                <Calendar
                    v-model="activateOn"
                    showButtonBar
                    placeholder="(Optional)"
                    :disabled="!editActivateOn"
                    class="w-full bg-white"
                />
                <p v-if="errors.activate_on" class="text-sm text-danger">{{ errors.activate_on[0] }}</p>  
                <div v-if="editActivateOn" class="flex items-center justify-end mt-4 mb-8">
                    <span @click="cancelEditActivateOn" class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary">{{ $t('actions.cancel') }}</span>
                    <button class="px-6 py-2 ml-3 text-white rounded bg-secondary">{{ $t('actions.update') }}</button>
                </div>
        </form>

        <h1 class="text-[1.35rem] font-semibold lg:w-5/6 mx-auto mt-16">{{ $t('titles.products') }}</h1>
        <ul class="grid grid-cols-3 gap-3 py-4 mx-auto mb-3 lg:w-5/6">
            <h1 class="col-span-3 text-xl text-success">Total - {{ total }}</h1>
            <li
                v-for="item in selectedTypes"
                :key="item.product_type_id"
                class="relative p-4 bg-white border border-gray-300 rounded shadow"
            >
            <!-- <span @click="unselectType(item.product_type_id , item.quantity)" :class="`absolute bg-white top-[-10px] right-[-10px] cursor-pointer text-danger`" class="material-icons-outlined">cancel</span> -->
            <p class="flex items-center justify-between py-1"><span class="block w-1/2">{{ $t('labels.name') }}</span>-<span class="block w-1/2 text-end">{{ item.productName }}</span></p>
            <p class="flex items-center justify-between py-1"><span class="block w-1/2">Type</span>-<span class="block w-1/2 text-end">{{ item.typeName }}</span></p>
            <p class="flex items-center justify-between py-1"><span class="block w-1/2">Quantity</span>-<span class="block w-1/2 text-end">{{ item.quantity }}</span></p>
            <p class="flex items-center justify-between py-1"><span class="block w-1/2">Amount</span>-<span class="block w-1/2 text-end">{{ Math.trunc(item.amount) }}</span></p>
            <p class="flex items-center justify-between py-1"><span class="block w-1/2">Iccid</span>-<span class="block w-1/2 text-end">{{ item.iccid }}</span></p>
            <p class="flex items-center justify-between py-1 mb-10 line-clamp-2"><span class="block w-1/2">Remark</span>-<span class="block w-1/2 text-end">{{ item.remark }}</span></p>
            </li>    
        </ul>

        <div class="mx-auto mt-16 lg:w-5/6">
            <h1 class="text-[1.5rem] font-semibold text-primary mb-4">Slip</h1>
            <input @change="uploadSlip" type="file" class="hidden" id="slipUpload">
            <button @click="focusSlip" class="w-1/3 py-3 text-white rounded outline-none bg-secondary rign-0">
                {{ $t('actions.upload_slip') }}
            </button>
            <img v-if="slip?.url" class="w-1/3 mt-4" :src="slip?.url" alt="">
        </div>

        <div class="mx-auto mt-16 lg:w-5/6">
            <h1 class="text-[1.5rem] font-semibold text-primary mb-4">NRC files</h1>
            <input @change="uploadFiles" type="file" class="hidden" id="fileupload">
            <button @click="focusAdditionalFile" class="w-1/3 py-3 text-white rounded outline-none bg-secondary ring-0">
                Upload NRC Files
            </button>
            <h2></h2>
            <div v-if="filesPreview?.length" class="grid grid-cols-3 gap-3 my-4">
                <img v-for="file in filesPreview" :key="file.id" class="h-fit" :src="file.url" alt="">
            </div>
            <div class="flex justify-center" v-if="filesPreview?.length && formData.has('files[]')">
                <button @click="uploadAllFiles" class="px-6 py-3 text-white rounded outline-none h-fit bg-success rign-0">{{ $t('actions.upload_all') }}</button>
            </div>
            <div v-if="additionalFiles?.length" class="grid grid-cols-3 gap-3 my-4">
                <div v-for="file in additionalFiles" :key="file.id"  class="flex items-center bg-gray-100 rounded">
                    <img class="h-fit" :src="file.url" alt="">
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from 'vue3-toastify';

import  {
    getStates,
    getAcceptTimes,
} from '@/services/commonApis'
import OrderLogs from "@/components/orders/OrderLogs.vue";
import LoadingIndi from "@/components/LoadingIndi.vue";

//prime vue components
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';

const route = useRoute();
const loading = ref(false);
const states = ref([]);
const acceptTimes = ref([]);

const order = ref({});
const total = ref(0);
const errors = ref({});
const showLogs = ref(false);

const basicInfo = ref({});
const paymentInfos = ref({});
const orderReturn = ref({});
const deliveryInfos = ref({});
const activateOn = ref('');

const editBasics = ref(false);
const editDelivery = ref(false);
const editActivateOn = ref(false);
const selectedTypes = ref([]);

const slip = ref({});
const filesPreview = ref([]);
const additionalFiles = ref([]);
let formData = new FormData();

const copyOrder = () => {
    let formatProducts = () => {
        let text = '';
        order.value.products?.forEach((prod , index) => {
            text += `${index+1}. ${prod.productName} (${prod.typeName}) × ${prod.quantity} \n${prod.remark ? prod.remark : ''} \n\n`;
        })
        return text;
    }
    let text = `Name - ${basicInfo.value.name}\nPhone no. - ${basicInfo.value.phone_no ? basicInfo.value.phone_no : ''}\nPostal code - ${basicInfo.value.postal_code}\nAddress - ${basicInfo.value.state.name} ${basicInfo.value.address} ${basicInfo.value.apartment_name ? basicInfo.value.apartment_name : ''}\nDelivery type - ${deliveryInfos.value.delivery_type ? deliveryInfos.value.delivery_type.name : ''}\nDelivery accept date - ${deliveryInfos.value.delivery_accept_date ? deliveryInfos.value.delivery_accept_date : ''}\nDelivery accept time - ${deliveryInfos.value.delivery_accept_time ? deliveryInfos.value.delivery_accept_time.name : ''}\n\n• Order Items List\n\n${formatProducts()}`
    navigator.clipboard.writeText(text)
    toast.success("Copied order details to clipboard.")
}

const checkPostalCode = (e) => {
    ApiService.get(`https://apis.postcode-jp.com/api/v5/postcodes/${e.target.value}?apikey=fFlNHwUdI50gi3MI6nfA20S577K4E7punaX39FR`).then((res) => {
        let pref = res[0].pref;
        if (pref) {
            order.value.address = `${res[0].city} ${res[0].town}`
            let state = states.value.find(s => s.name == pref);
            if (state != undefined) {
                order.value.state_id = state.id;
            } 
        }
    }).catch(() => {})
}


const convertDate = (dateString) => {
    const myanmarDate = new Date(dateString);
    const year = myanmarDate.getFullYear();
    const month = myanmarDate.getMonth() + 1;
    const day = myanmarDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
}


const cancelEditActivateOn = () => {
    editActivateOn.value = false;
    activateOn.value = order.value.activate_on
}
const updateActivateOn = () => {
    const tid = toast.loading('Updating, please wait . . .');
    activateOn.value = convertDate(activateOn.value);
    ApiService.put(`orders/update-activate-on/${route.params.id}` , {activate_on : activateOn.value}).then((res) => {
        toast.update(tid, {
            render: "Updated activate on date.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        activateOn.value = res.data;
        editActivateOn.value = false;
    }).catch((res) => {
        toast.update(tid, {
            render: "Error updating activate on date.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}

const cancelEditBasics = () => {
    editBasics.value = false;
    insertDeliveryInfos(order.value);
}
const updateBasicsInfo = () => {
    const tid = toast.loading('Updating, please wait . . .');
    ApiService.put(`affiliate/orders/update-basic-info/${route.params.id}` , basicInfo.value).then((res) => {
        toast.update(tid, {
            render: "Updated basic info.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        insertBasicInfos(res.data);
        editBasics.value = false;
    }).catch((res) => {
        toast.update(tid, {
            render: "Error updating basic info.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}


const focusSlip = () => {
    document.getElementById('slipUpload').click()
}

const uploadSlip = (e) => {
    const id = toast.loading('Uploading, please wait . . .');
    let formData = new FormData();
    formData.set('slip' , e.target.files[0])
    formData.set('order_id' , Number(order.value.id));
    let headers = {
        "Content-Type" : "multipart/form-data",
    }
    ApiService.post('slips' , formData , headers).then((res) => {
        toast.update(id, {
            render: "Uploaded slip.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        slip.value = {url : res.data};
    }).catch(() => {
        toast.update(id, {
            render: "Error uploading slip.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const focusAdditionalFile = () => {
    document.getElementById('fileupload').click()
}

const uploadFiles = (e) => {
    if (e.target.files?.length) {
        formData.append('files[]' , e.target.files[0])
        let tempUrl = URL.createObjectURL(e.target.files[0]);
        let tempId = Math.floor(Math.random() * 10);
        let tempObj = {
            id : tempId,
            url : tempUrl
        }
        filesPreview.value.push(tempObj)
    }
}

const uploadAllFiles = () => {
    const id = toast.loading('Uploading, please wait . . .');
    formData.append('order_id' , order.value.id);
    let headers = {
        "Content-Type" : "multipart/form-data",
    }
    ApiService.post('files' , formData , headers).then((res) => {
        toast.update(id, {
            render: "Uploaded files.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        additionalFiles.value = res.data
        filesPreview.value = [];
        formData.delete("files[]")
    }).catch(() => {
        toast.update(id, {
            render: "Error uploading files.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const insertBasicInfos = (od) => {
    basicInfo.value.name = od.name
    basicInfo.value.email = od.email
    basicInfo.value.phone_no = od.phone_no
    basicInfo.value.fb_link = od.fb_link
    basicInfo.value.postal_code = od.postal_code
    basicInfo.value.state_id = od.state_id
    basicInfo.value.state = od.state
    basicInfo.value.address = od.address
    basicInfo.value.apartment_name = od.apartment_name
    basicInfo.value.source_id = od.source_id
}
const insertPaymentInfos = (od) => {
    paymentInfos.value.payment_status = od.payment_status
    paymentInfos.value.payment_method_id = od.payment_method_id
    paymentInfos.value.cod_amount = od.cod_amount
}
const insertDeliveryInfos = (od) => {
    deliveryInfos.value.delivery_agent_id = od.delivery_agent_id
    deliveryInfos.value.delivery_agent = od.delivery_agent
    deliveryInfos.value.delivery_accept_time_id = od.delivery_accept_time_id
    deliveryInfos.value.delivery_accept_time = od.delivery_accept_time
    deliveryInfos.value.delivery_date = od.delivery_date
    deliveryInfos.value.delivery_accept_date = od.delivery_accept_date
    deliveryInfos.value.delivery_status = od.delivery_status
    deliveryInfos.value.tracking_code = od.tracking_code
    deliveryInfos.value.delivery_type_id = od.delivery_type_id
    deliveryInfos.value.delivery_type = od.delivery_type
}

const insertReturnInfos = (od) => {
    orderReturn.value = {
        return_status : od.order_return?.return_status,
        return_remark : od.order_return?.return_remark,
        start_date : od.order_return?.start_date,
        done_date : od.order_return?.done_date,
    }
}

const getData = async () => {
    await getStates(1 , 'all').then((res) => {
        states.value = res.data;
    })
    getAcceptTimes(1 , 'all').then((res) => {
        acceptTimes.value = res.data;
    })
}


onMounted(() => {
    loading.value = true;
    ApiService.get(`affiliate/orders/${route.params.id}`).then((res) => {
        order.value = res.data;
        insertBasicInfos(order.value);
        insertPaymentInfos(order.value);
        insertDeliveryInfos(order.value);
        insertReturnInfos(order.value);
        activateOn.value = order.value.activate_on;
        slip.value = order.value.slip;
        additionalFiles.value = order.value.files;
        order.value.products.forEach((t) => {
            total.value += t.pivot.amount;
            let obj = {
                product_type_id : t.pivot.product_type_id,
                productName : t.product.name,
                typeName : t.name,
                amount : t.pivot.amount,
                quantity : t.pivot.quantity,
                iccid : t.pivot.iccid,
                remark : t.pivot.remark
            }
            selectedTypes.value.push(obj)
        })
        order.value.products = selectedTypes.value;
        order.value.types = []
        loading.value = false;
        getData().catch(() => {
            toast.error("Error fetching related data.")
        });
    }).catch(() => {
        loading.value = false;
        toast.error("Error fetching data.");
    })

})

</script>

<style scoped>

</style>