<template>
  <div>
    <LoadingIndi v-if="loading" />
    <DataTable v-else :value="types" tableStyle="min-width: 50rem">
      <template #header>
        <div class="flex justify-between">
          <div class="flex items-center justify-center gap-3">
            <router-link :to="{name : 'ProductsView' , query : {page : 1 , perPage : 10}}" class="mt-1">
                <span class="material-icons-round">west</span> 
            </router-link>
            <h1 class="text-2xl font-normal">{{ product }}</h1>
          </div>
          <Button
            v-tooltip.left="'Create New Product Type'"
            @click="createForm = true"
            class="text-white border-0 bg-secondary"
            :label="$t('actions.create')"
            size="small"
          />
        </div>
      </template>
      <Column field="id" :header="$t('labels.id')"></Column>
      <Column field="image" header="Image">
        <template #body="slotProps">
          <Image
            v-if="slotProps.data.image"
            :src="slotProps.data.image?.url"
            alt="Image"
            width="160"
            preview
          />
        </template>
      </Column>
      <Column field="name" :header="$t('labels.name')"></Column>
      <Column field="hide" header="Hide from affiliates">
          <template #body="slotProps">
              <span class="px-2 py-1 text-white rounded-lg" :class="slotProps.data.hide ? 'bg-danger' : 'bg-success'">{{ slotProps.data.hide ? "YES" : "NO" }}</span>
          </template>
      </Column>
      <Column field="custom" :header="$t('labels.actions')">
        <template #body="slotProps">
          <div class="relative flex gap-2 text-white">
            <button
              v-tooltip.bottom="'Edit Product Type'"
              @click="showEditForm(slotProps.data.id)"
              class="flex items-center justify-center p-2 text-sm rounded-full bg-secondary"
            >
              <span style="font-size: 1.2rem" class="material-icons-round"
                >edit</span
              >
            </button>
            <button
              v-tooltip.bottom="'Delete Product Type'"
              @click="deleteType(slotProps.data.id)"
              class="flex items-center justify-center p-2 text-sm rounded-full bg-danger"
            >
              <span style="font-size: 1.2rem" class="material-icons-round"
                >delete</span
              >
            </button>
          </div>
        </template>
      </Column>
    </DataTable>

    <!-- Create Form -->
    <Dialog
      v-model:visible="createForm"
      modal
      header="Create Product Type"
      :style="{ width: '30rem' }"
    >
      <CreateType @created="refreshData" />
    </Dialog>

    <!-- Edit Form -->
    <Dialog
      v-model:visible="editForm"
      modal
      header="Edit Product Type"
      :style="{ width: '30rem' }"
    >
      <EditType :id="editId" @edited="refreshData" />
    </Dialog>

  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";

import CreateType from "@/components/product_types/CreateType.vue";
import EditType from "@/components/product_types/EditType.vue";
import LoadingIndi from "@/components/LoadingIndi.vue";

//primevue component imports
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Image from "primevue/image";

const route = useRoute();
const productId = route.params.id;
const loading = ref(true);
const product = ref({});
const types = ref([]);
const createForm = ref(false);
const editForm = ref(false);
const editId = ref(0);

const fetchData = () => {
  loading.value = true;
  ApiService.get(`products/${productId}`)
    .then((res) => {
      loading.value = false;
      product.value = res.data.name;
      types.value = res.data.types;
    })
    .catch(() => {
      loading.value = false;
    });
};

const refreshData = async () => {
  createForm.value = false;
  editForm.value = false;
  fetchData();
};

const showEditForm = (id) => {
  editId.value = id;
  editForm.value = true;
};

const deleteType = (id) => {
  const tid = toast.loading("Deleting, please wait . . .");
  ApiService.delete(`product-types/${id}`)
    .then(async () => {
      toast.update(tid, {
        render: "Deleted product type.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false
      });
      await refreshData();
    })
    .catch(() => {
      toast.update(tid, {
        render: "Error deleting product type.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false
      });
    });
};

onMounted(async () => {
  fetchData();
});
</script>

<style scoped></style>
