<template>
    <div class="flex flex-wrap justify-center p-2 pb-12 text-gray-700">
        <div class="flex items-center w-full gap-3 mx-auto lg:w-5/6">
            <router-link :to="{name : 'OrdersView' , query : {page : 1 , delivery_status : ['ordered'] , perPage : 10}}" class="mt-1">
                <span class="material-icons-round">west</span> 
            </router-link>
            <h1 class="text-2xl">Order Details</h1>
        </div>
        <div class="flex justify-end w-full lg:w-5/6">
            <p>
                <span>Staff -</span>
                <span class="font-bold text-primary">{{ order.user?.name }}</span>
            </p>
        </div>
        <main class="w-full lg:w-5/6">
            <h1 class="mt-8 text-[1.35rem] text-primary font-semibold">User Info</h1>
            <!-- name / email -->
            <div class="grid gap-3 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="name">{{ $t('labels.name') }}</label>
                    <p class="items">{{ order.name }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="email">Email</label>
                    <p class="items">{{ order.email }}</p>
                </div>
            </div>

            <!-- phone / fb link -->
            <div class="grid gap-3 mt-4 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="phone_no">Phone number</label>
                    <p class="items">{{ order.phone_no ? order.phone_no : '-' }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="fb_link">Facebook Link</label>
                    <p class="items">{{ order.fb_link ? order.fb_link : '-' }}</p>
                </div>
            </div>

            <!-- postal code / state -->
            <div class="grid gap-3 mt-4 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="postal_code">Postal Code</label>
                    <p class="items">{{ order.postal_code }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="state_id">State</label>
                    <p class="items">{{ order.state?.name }}</p>
                </div>
            </div>

            <!-- address -->
            <div class="mt-4">
                <label for="address" class="block mb-2">Address</label>
                <p class="items min-h-20">{{ order.address }}</p>
            </div>

            <h1 class="mt-8 text-[1.35rem] text-primary font-semibold">Payment Info</h1>
            <!-- payment method / payment status -->
            <div class="grid gap-3 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="payment_method_id">Payment method</label>
                    <p class="items">{{ order.payment_method?.name }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="payment_status">Payment status</label>
                    <p class="items">{{ order.payment_status }}</p>
                </div>
            </div>

            <h1 class="mt-8 text-[1.35rem] text-primary font-semibold">Delivery Info</h1>
            <!-- delivery accept time / delivery method -->
            <div class="grid gap-3 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="delivery_accept_time_id">Delivery accept time</label>
                    <p class="items">{{ order.delivery_accept_time?.name }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="delivery_method_id">Delivery Method</label>
                    <p class="items">{{ order.delivery_agent?.name }}</p>
                </div>
            </div>

            <!-- Delivery date / Delivery accept date -->
            <div class="grid gap-3 mt-4 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="delivery_date">Delivery Date</label>
                    <p class="items">{{ order.delivery_date ? order.delivery_date : '-' }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="delivery_accept_date">Delivery accept date</label>
                    <p class="items">{{ order.delivery_accept_date }}</p>
                </div>
            </div>

            <div class="grid gap-3 mt-4 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="tracking_code">Delivery Status</label>
                    <p class="items">{{ order.delivery_status }}</p>
                </div>
                <div>
                    <label class="block mb-1" for="tracking_code">Tracking code</label>
                    <p class="items">{{ order.tracking_code ? order.tracking_code : '-' }}</p>
                </div>
            </div>

            <!-- products -->
            <DataTable :value="order.products" class="mt-12" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-between">
                    <h1 class="text-[1.35rem] font-semibold text-primary">Products</h1>
                </div>
            </template>
            <Column field="product.name" header="Product"></Column>
            <Column field="name" header="Type"></Column>
            <Column field="pivot.quantity" header="Quantity"></Column>
            <Column field="pivot.amount" header="Amount"></Column>
            <Column field="pivot.iccid" header="Iccid"></Column>
            <Column field="pivot.remark" header="Remark"></Column>
        </DataTable>

        </main>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import ApiService from '@/services/ApiService';

//primevue component imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';


const route = useRoute();
const order = ref({});

const get = () => {
    return ApiService.get(`orders/${route.params.id}`);
}
get().then((res) => {
    order.value = res.data
    let firstPart = order.value.postal_code.slice(0, 3);
    let secondPart = order.value.postal_code.slice(3);
    order.value.postal_code = firstPart + '-' + secondPart;
}).catch(() => {})

</script>

<style scoped>
tr {
    border: 1px gray solid;
}
th {
    text-align: left;
    padding-left: 15px;
}
</style>