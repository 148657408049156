<template>
    <form @submit.prevent="createStatus">
        <div>
            <label class="block mb-1 text-label" for="name">{{ $t('labels.name') }}</label>
            <InputText
                v-model="status.name"
                name="name"
                type="text"
                placeholder="Status name"
                class="w-full"
                />      
                <p v-if="errors.name" class="text-sm text-danger">{{ errors.name[0] }}</p>          
        </div>
        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">{{ $t('actions.create') }}</button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits} from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';
//prime vue components
import InputText from 'primevue/inputtext';

const emit = defineEmits(['created']);

const status = ref({
    name : ''
})
const errors = ref({});

const createStatus = () => {
    const id = toast.loading('Creating, please wait . . .');
    ApiService.post('statuses' , status.value).then(() => {
        toast.update(id, {
            render: "Created status.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('created');
    }).catch((res) => {
        toast.update(id, {
            render: "Error creating status.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}
</script>

<style scoped>

</style>