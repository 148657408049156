<template>
    <div>
        <div v-if="loading">
            <LoadingIndi />
        </div>
        <ul v-else>
            <li
                v-for="log in logs"
                :key="log.id"
                class="flex items-center justify-between py-4 border-b border-gray-300"
            >
            <p>
                <span class="font-semibold text-primary">{{ log?.user?.name }}</span>
                updated
                <span class="font-semibold text-primary">{{ log.field }}</span>
                status form
                <span class="font-semibold text-primary">{{ log.from }}</span>
                to
                <span class="font-semibold text-primary">{{ log.to }}.</span>
            </p>
            <p>
                {{ convertDate(log.created_at) }}
            </p>
            </li>
            <li class="flex items-center justify-between py-4 border-b border-gray-300">
                <p>
                    <span  class="font-semibold text-primary">{{ props.creator }}</span>
                    created order.
                </p>
                <p>{{ props.created_at }}</p>
            </li>
        </ul>
    </div>
</template>

<script setup>
import ApiService from '@/services/ApiService';
import { defineProps, onMounted, ref } from 'vue';
import LoadingIndi from '../LoadingIndi.vue';
const props = defineProps({
    order_id : {
        required : true,
        type : Number
    },
    creator : {
        required : true,
        type : String
    },
    created_at : {
        required : true,
        type : String
    }
})
const logs = ref([]);
const loading = ref(false);

const convertDate = (originalTimestamp) => {
    const dateObject = new Date(originalTimestamp);
    const formattedDate = `${dateObject.getFullYear()}-${('0' + (dateObject.getMonth() + 1)).slice(-2)}-${('0' + dateObject.getDate()).slice(-2)} , ${('0' + dateObject.getHours()).slice(-2)}:${('0' + dateObject.getMinutes()).slice(-2)}:${('0' + dateObject.getSeconds()).slice(-2)}`;
    return formattedDate;
}
onMounted(() => {
    loading.value = true;
    ApiService.get(`logs/${props.order_id}`).then((res) => {
        logs.value = res.data;
        loading.value = false;
    }).catch(() => {
        loading.value = false;
    })
})
</script>

<style scoped>

</style>