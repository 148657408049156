<template>
    <div class="flex justify-center h-screen px-1 py-24">
        <div class="flex items-center w-full h-fit lg:w-2/3" style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
            <div class="hidden md:w-1/2 md:block">
                <img src="@/assets/images/dashboard.svg" alt="dashboard photo">
            </div>
            <form @submit.prevent="register" class="w-full p-6 md:w-1/2">
                <h1 class="mb-8 text-3xl font-semibold text-center text-primary">{{ $t('actions.register') }}</h1>
                <div>
                    <label for="name" class="block mb-1">{{ $t('labels.name') }}</label>
                    <InputText
                        name="name"
                        required
                        v-model="user.name"
                        class="w-full"
                        placeholder="John Doe"
                    />
                    <p v-if="errors?.name" class="mt-1 text-danger">{{ errors.name[0] }}</p>
                </div>
                <div class="mt-4">
                    <label for="email" class="block mb-1">Email</label>
                    <InputText
                        name="email"
                        type="email"
                        required
                        v-model="user.email"
                        class="w-full"
                        placeholder="example@gmail.com"
                    />
                    <p v-if="errors?.email" class="mt-1 text-danger">{{ errors.email[0] }}</p>
                </div>
                <div class="mt-4">
                    <label for="password" class="block mb-1">Password</label>
                    <InputText
                        name="password"
                        type="password"
                        required
                        v-model="user.password"
                        class="w-full"
                        placeholder=". . . ."
                    />
                    <p v-if="errors?.password" class="mt-1 text-danger">{{ errors.password[0] }}</p>
                </div>
                <div class="mt-4">
                    <label for="password_confirmation" class="block mb-1">Confirm Password</label>
                    <InputText
                        name="password_confirmation"
                        type="password"
                        required
                        v-model="user.password_confirmation"
                        class="w-full"
                        placeholder=". . . ."
                    />
                    <p v-if="errors?.password_confirmation" class="mt-1 text-danger">{{ errors.password_confirmation[0] }}</p>
                </div>
                <p v-if="errors?.default" class="mt-1 text-danger">{{ errors.default }}</p>
                <button class="w-full py-3 mt-8 text-white transition-all duration-300 border rounded-lg bg-primary hover:bg-white border-primary hover:text-primary">{{ $t('actions.register') }}</button>
                <router-link :to="{name : 'LoginView'}">
                    <button class="w-full py-3 mt-8 underline transition-all duration-300 rounded-lg">Already have an account? Login</button>
                </router-link>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import InputText from "primevue/inputtext";
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

const router = useRouter();

const user = ref({
    name : '',
    email : '',
    password : '',
    password_confirmation : ''
})
const errors = ref({});

const register = () => {
    const id = toast.loading('Registering, please wait . . .');
    ApiService.post('register' , user.value).then(() => {
        toast.update(id, {
            render: "Registered successfully. Please Login.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        router.push({name : 'LoginView'})
    }).catch((res) => {
        toast.update(id, {
            render: "Register failed.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res.response?.data?.errors;
        setTimeout(() => {
            errors.value = {};
        } , 5000)
    })
}

</script>

<style scoped>

</style>