<template>
  <div>
    <div class="mt-4 mb-2">
        <input @change="uploadImage" type="file" id="imageUpload" class="hidden">
        <label for="image_input" class="block mb-1">Image</label>
        <Button :disabled="loading" @click="focusInput" class="flex w-full text-left text-gray-400 border border-gray-300" size="small">
            <span class="material-icons-outlined">file_upload</span>
            <span class="ml-2">Image</span>
        </Button>
        <p v-if="errors.image" class="text-sm text-danger">{{ errors.image[0] }}</p>          
    </div>
    <div v-if="image.url">
      <Image :src="image.url" alt="Image" width="160" preview />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted , defineEmits } from "vue";
import Image from "primevue/image";
import Button from "primevue/button";
import { toast } from "vue3-toastify";
import ApiService from '@/services/ApiService'

const props = defineProps({
  initial: Object
});
const emits = defineEmits(['upload']);

const image = ref({
  id: "",
  url: ""
});
const loading = ref(false);
const errors = ref({});

const focusInput = () => {
    document.getElementById('imageUpload').click();
}

const uploadImage = (e) => {
    loading.value = true;
    const id = toast.loading('Uploading, please wait . . .');
    let formData = new FormData();
    formData.set('image' , e.target.files[0])
    let headers = {
        "Content-Type" : "multipart/form-data",
    }
    ApiService.post('images' , formData , headers).then((res) => {
        toast.update(id, {
            render: "Uploaded image.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        image.value = res.data;
        console.log(image.value)
        emits('upload' , res.data?.id);
        loading.value = false;
    }).catch(() => {
        toast.update(id, {
            render: "Error uploading image.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        loading.value = false;
    })
}

onMounted(() => {
  image.value.id = props.initial?.id;
  image.value.url = props.initial?.url;
});
</script>

<style scoped></style>
