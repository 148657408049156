<template>
    <div class="p-2 pb-12">
        <LoadingIndi v-if="loading" />
        <div v-else class="mx-auto lg:w-11/12">
            <div class="flex items-center gap-3">
                <router-link :to="{name : 'ProductsView'}">
                    <span class="material-icons-round">west</span> 
                </router-link>
                <h1 class="text-2xl">{{ product.name }}</h1>
            </div>
            <div class="my-6">
                <h1 class="text-[1.35rem] font-semibold text-primary">Filters</h1>
                <div class="grid items-end grid-cols-2 gap-8 mt-2 mb-6 md:grid-cols-4">
                    <div>
                        <label class="block mb-1" for="ordered_date">By Month</label>
                        <Calendar
                            @update:modelValue="filterRecords($event , 'month')"
                            @clear-click="clearFilter('month')"
                            v-model="filters.month"
                            view="month"
                            dateFormat="mm/yy"
                            showButtonBar
                            placeholder="01-2024"
                            class="w-full"
                        />     
                    </div>
                    <div class="relative flex items-end h-full col-span-3">
                        <label class="absolute top-0 left-0 w-full mb-1" for="ordered_date">By Period of Time</label>
                        <Calendar
                            v-model="filters.start"
                            view="month"
                            dateFormat="mm/yy"
                            showButtonBar
                            placeholder="Start Date"
                            class="w-1/3 h-fit"
                        />
                        <p class="flex items-center h-full mx-2">
                            <span class="mt-6">-</span>
                        </p>
                        <Calendar
                            v-model="filters.end"
                            view="month"
                            dateFormat="mm/yy"
                            showButtonBar
                            placeholder="End Date"
                            class="w-1/3 h-fit"
                        />
                        <button @click="clearFilter('duration')" class="min-w-[42px] aspect-square h-[42px] flex text-white items-center justify-center ml-2 rounded bg-label">
                            <span class="material-icons-outlined">close</span>
                        </button>
                        <button @click="filterRecords(null , 'duration')" class="min-w-[42px] aspect-square h-[42px] flex text-white items-center justify-center ml-2 rounded bg-primary">
                            <span class="material-icons-outlined">search</span>
                        </button>
                    </div>
                </div>
            </div>
            <div :class="loading ? 'hidden' : 'md:flex flex-wrap'" class="mx-auto mt-10 gap-x-12">
            <ul class="flex-grow">
                <li class="flex p-2 font-semibold text-primary" style="border: 1px grey solid;border-bottom: 0;">
                    <p class="inline-flex items-center w-1/2">
                        Product Type
                    </p>
                    <span>:</span>
                    <p class="w-1/2 text-center">Ordered Quantity</p>
                </li>
                <li v-for="record in records" :key="record.id" class="flex p-2"  style="border: 1px grey solid;border-bottom: 0">
                    <p class="w-1/2">{{ record.name }}</p>
                    <span>:</span>
                    <p class="w-1/2 text-center">{{ record.total_quantity }}</p>
                </li>
                <li class="flex p-2 font-semibold text-primary"  style="border: 1px grey solid;">
                    <p class="inline-flex items-center w-1/2">
                        <span class="material-icons-outlined">functions</span>
                        Total
                    </p>
                    <span>:</span>
                    <p class="w-1/2 text-center">{{ calculateTotal() }}</p>
                </li>
            </ul>
            <div v-if="records.length > 0" class="lg:w-1/2 md:w-2/3">
                <canvas class="w-full" id="myChart"></canvas>
            </div>
        </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import ApiService from '@/services/ApiService';
import LoadingIndi from '@/components/LoadingIndi.vue';
import Chart from 'chart.js/auto';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

import Calendar from 'primevue/calendar';

const route = useRoute();
const loading = ref(false);
const product = ref({});
const records = ref([]);

const convertMonth = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return `${month < 10 ? '0' : ''}${month}-${year}`;
}

const filters = ref({
    month : convertMonth(new Date()),
    start : '',
    end : ''
})

// let myChart = "";

const calculateTotal = () => {
    let total = 0;
    records.value.forEach(r => total += Number(r.total_quantity))
    return total;
}

const drawChart = () => {
    let ctx = document.getElementById('myChart').getContext('2d');
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: records.value.map(r => r.name),
            datasets: [{
                label: '# of Quantity',
                data: records.value.map(r => r.total_quantity),
                backgroundColor: [
                    'rgb(47, 113, 161)',

                ],
                borderColor: [
                    'rgb(47, 113, 161)',
                ],
                borderWidth: 1,
                borderRadius : 10,
                barThickness : 100
            }]
        },
        options: {
            scales: {
                y : {
                    beginAtZero: true,
                },
            }
        }
    });
}

const fetchData = async (params) => {
    loading.value = true;
    return ApiService.get(`analytics/${route.params.id}` , {params : params}).then((res) => {
        loading.value = false;
        product.value = res.data.product;
        records.value = res.data.records;
        product.value?.types.forEach(t => {
            let isIncluded = false;
            records.value.forEach(r => {
                if (t.id == r.id) {
                    isIncluded = true;
                }
            })
            if (!isIncluded) {
                records.value.push({
                    id : t.id,
                    name : t.name,
                    total_quantity : 0
                })
            }
        })
        setTimeout(() => {
            drawChart();
        } , 500)
    }).catch(() => {
        loading.value = false;
        toast.error('Error fetching records')
    })
}

const filterRecords = (e , type) => {
    const params = {};
    if (type == 'month') {
        let month = convertMonth(e);
        if (month != '01-1970') {
            params.month = month;
        }
    } else if(type == 'duration') {
        let start = convertMonth(filters.value.start);
        let end = convertMonth(filters.value.end);
        params.start = start != '01-1970' ? start : null;
        params.end = end != '01-1970' ? end : null;
    }
    fetchData(params);
}

const clearFilter = (type) => {
    if (type == 'month') {
        //filterRecords will handle it
        return;
    }
}

onMounted(() => {
    fetchData(filters.value);
})

</script>

<style scoped>

</style>