<template>
    <form @submit.prevent="createRule">
        <div class="grid grid-cols-2 gap-4 mb-6">
            <div>
                <label class="block mb-1" for="title">Title</label>
                <InputText
                    v-model="rule.title"
                    name="title"
                    type="text"
                    placeholder="product title"
                    class="w-full"
                    />      
                    <p v-if="errors.title" class="text-sm text-danger">{{ errors.title[0] }}</p>          
            </div>
            <div>
                <label class="block mb-1" for="sub_title">Sub Title</label>
                <InputText
                    v-model="rule.sub_title"
                    name="sub_title"
                    type="text"
                    placeholder="product sub_title"
                    class="w-full"
                    />      
                    <p v-if="errors.sub_title" class="text-sm text-danger">{{ errors.sub_title[0] }}</p>          
            </div>
        </div>
        <div>
            <label for="body" class="block mb-1">Paragraph</label>
            <quill-editor
                v-model:value="rule.body"
            />
        </div>
        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">Create</button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits} from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';
import { useRoute } from 'vue-router';

//prime vue components
import InputText from 'primevue/inputtext';

import { quillEditor } from 'vue3-quill'

const emit = defineEmits(['created']);

const route = useRoute();
const rule = ref({
    title : '',
    sub_title : '',
    body : '',
    product_id : route.params.id
})
const errors = ref({});

const createRule = () => {
    const id = toast.loading('Creating, please wait . . .');
    ApiService.post('rules' , rule.value).then(() => {
        toast.update(id, {
            render: "Created rule.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('created');
    }).catch((res) => {
        toast.update(id, {
            render: "Error creating rule.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}
</script>

<style scoped>
.ql-container {
    min-height: 200px;
}
</style>