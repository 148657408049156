<template>
    <div>
        <!-- filters -->
        <h1 class="text-[1.35rem] text-primary font-semibold mt-8 px-3">{{ $t('titles.filters') }}</h1>
        <div class="grid grid-cols-2 gap-4 px-3 mt-2 mb-6 md:grid-cols-4">
            <div>
                <label class="block mb-1" for="id">Report ID</label>
                <InputText
                    v-model="filters.id"
                    @change="filterList(currentPage)"
                    placeholder="report id"
                    name="id"
                    class="w-full"
                    :class="filters.id ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="title">Title</label>
                <InputText
                    v-model="filters.title"
                    @change="filterList(currentPage)"
                    placeholder="title"
                    name="title"
                    class="w-full"
                    :class="filters.title ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="sub_title">Sub Title</label>
                <InputText
                    v-model="filters.sub_title"
                    @change="filterList(currentPage)"
                    placeholder="sub title"
                    name="sub_title"
                    class="w-full"
                    :class="filters.sub_title ? 'border-success' : ''"
                />
            </div>
            <!-- category , sub category , status multi selects -->
            <div>
                <label class="block mb-1" for="category_id">Category</label>
                <MultiSelect
                    v-model="filters.category_id"
                    :options="categories"
                    option-label="name"
                    option-value="id"
                    @change="filterList(currentPage)"
                    placeholder="category"
                    display="chip"
                    class="w-full"
                    :class="filters.category_id?.length ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="sub_category_id">Sub Category</label>
                <MultiSelect
                    v-model="filters.sub_category_id"
                    :options="subCategories"
                    placeholder="sub category"
                    option-label="name"
                    option-value="id"
                    @change="filterList(currentPage)"
                    display="chip"
                    class="w-full"
                    :class="filters.sub_category_id?.length ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="status_id">Status</label>
                <MultiSelect
                    v-model="filters.status_id"
                    :options="statuses"
                    placeholder="status"
                    option-label="name"
                    option-value="id"
                    @change="filterList(currentPage)"
                    display="chip"
                    class="w-full"
                    :class="filters.status_id?.length ? 'border-success' : ''"
                />
            </div>
            <!-- report month date -->
            <div>
                <label class="block mb-1" for="report_month">Report Month</label>
                <Calendar
                    v-model="dates.report_month"
                    @update:modelValue="convertDate($event)"
                    @clear-click="clearDate('report_month')"
                    placeholder="report month"
                    view="month"
                    dateFormat="mm/yy" 
                    class="w-full"
                    :class="filters.report_month ? 'border-success' : ''"
                    showButtonBar
                />
            </div>
            <!-- phone_no , iccid string -->
            <div>
                <label class="block mb-1" for="phone_no">Phone Number</label>
                <InputText
                    name="phone_no"
                    v-model="filters.phone_no"
                    @change="filterList(currentPage)"
                    placeholder="phone number"
                    class="w-full"
                    :class="filters.phone_no ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="iccid">Iccid</label>
                <InputText
                    name="iccid"
                    v-model="filters.iccid"
                    @change="filterList(currentPage)"
                    placeholder="iccid"
                    class="w-full"
                    :class="filters.iccid ? 'border-success' : ''"
                />
            </div>
        </div>
        <LoadingIndi v-if="loading" />
        <DataTable
            v-else
            :row-hover="true"
            :value="reports"
            tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-between">
                    <h1 class="text-2xl font-normal">{{ $t('titles.reports') }}</h1>
                    <router-link :to="{name : 'CreateReport'}">
                        <Button :label="$t('actions.create')" class="px-6 py-3 text-white border-0 bg-secondary" size="small" />
                    </router-link>
                </div>
            </template>
            <Column field="id" :header="$t('labels.id')"></Column>
            <Column field="title" header="Title"></Column>
            <Column field="sub_title" header="Sub Title"></Column>
            <Column field="category.name" :header="$t('titles.categories')"></Column>
            <Column field="sub_category.name" :header="$t('titles.sub_categories')"></Column>
            <Column field="status.name" :header="$t('titles.statuses')"></Column>
            <Column field="user.name" header="User"></Column>
            <Column field="custom" :header="$t('labels.actions')">
                <template #body="slotProps">
                    <div class="relative flex items-center gap-3 text-white">
                        <button @click="showDetails(slotProps.data)" v-tooltip.bottom="'View Details'" class="flex items-center justify-center p-2 rounded-full bg-success hover:bg-success/90">
                            <span style="font-size: 1.4rem;" class="material-icons-round">visibility</span>
                        </button>
                        <router-link v-tooltip.bottom="'Edit Report'" class="flex items-center justify-center p-2 rounded-full bg-secondary hover:bg-secondary/90" :to="{name : 'EditReport' , params : {id : slotProps.data.id}}">
                            <span style="font-size: 1.4rem;" class="material-icons-round">edit</span>
                        </router-link>
                        <button @click="deleteReport(slotProps.data.id)" v-tooltip.bottom="'Delete Report'" class="flex items-center justify-center p-2 rounded-full bg-danger hover:bg-danger/90">
                            <span style="font-size: 1.4rem;" class="material-icons-round">delete</span>
                        </button>
                    </div>
                </template>
            </Column>
            <template #footer>
                <div class="flex justify-end w-full font-bold">
                    <div class="flex items-center gap-4">
                        <Dropdown
                            v-model="perPage"
                            :options="[10 , 20 , 30 , 50]"
                            @update:model-value="changePerPage($event)"
                        />
                        <Paginator
                            v-if="meta.total"
                            @page="onPaginate($event)"
                            :rows="meta.perPage"
                            v-model:first="meta.from"
                            :current-page-report-template="meta.current_page"
                            :totalRecords="meta.total"
                            ></Paginator>
                            <!-- :rowsPerPageOptions="[10, 20, 30]" -->
                    </div>
                </div>
            </template>
        </DataTable>
        <Dialog v-model:visible="viewDetails.dialog" modal :header="viewDetails.report.title" :style="{ width: '60rem' }">
            <div class="grid grid-cols-2 gap-4">
                <Fieldset legend="Phone Number">
                    <p class="mb-2 ml-2">{{ viewDetails.report.phone_no ? viewDetails.report.phone_no : '-' }}</p>
                </Fieldset>
                <Fieldset legend="Iccid">
                    <p class="mb-2 ml-2">{{ viewDetails.report.iccid ? viewDetails.report.iccid : '-' }}</p>
                </Fieldset>
                <Fieldset legend="Body" class="col-span-2 max-h-[30rem]">
                    <p  v-html="viewDetails.report.body" class="col-span-2 mb-2 ml-2"></p>
                </Fieldset>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRouter , useRoute } from 'vue-router';
import ApiService from '@/services/ApiService';
import { getCategories , getSubCategories , getStatuses } from '@/services/commonApis.js'

//primevue component imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Paginator from 'primevue/paginator';
import MultiSelect from 'primevue/multiselect';
import LoadingIndi from '@/components/LoadingIndi.vue';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import Cookies from 'js-cookie'
import { toast } from 'vue3-toastify';

const router = useRouter();
const route = useRoute();
const viewDetails = ref({
    dialog : false,
    report : {}
})

const showDetails = (item) => {
    viewDetails.value.dialog = true;
    viewDetails.value.report = item;
}

const currentPage = ref(isNaN(+route.query.page) ? 1 : +route.query.page);
const perPage = ref(isNaN(+route.query.perPage) ? 10 : +route.query.perPage)
const meta = ref({});
const reports = ref([]);
const loading = ref(true);
const categories = ref([]);
const subCategories = ref([]);
const statuses = ref([]);

const toArray = (value) => {
      if (value === undefined || value === null) {
        return [];
      } else if (Array.isArray(value)) {
        return value;
      } else {
        return [value];
      }
};

const filters = ref({
    id : route.query.id,
    perPage : 10,
    title : route.query.title,
    sub_title : route.query.sub_title,
    report_month : route.query.report_month,
    category_id : toArray(route.query['category_id']),
    sub_category_id : toArray(route.query['sub_category_id']),
    status_id : toArray(route.query['status_id']),
    iccid : route.query.iccid,
    phone_no : route.query.phone_no,
})
const dates = ref({
    report_month : ''
});

watch(route , async () => {
    filters.value = {
        ...filters.value,
        perPage : perPage.value
    };
    let fl = JSON.stringify(filters.value);
    Cookies.set('report_filter' , fl);
})

const fetchData = async (page) => {
    loading.value = true;
    return ApiService.get('reports' , {params : {...filters.value , page : page , perPage : perPage.value}}).then((res) => {
        loading.value = false;
        reports.value = res.data
        meta.value = res.meta
    }).catch(() => {
        loading.value = false;
    })
}

const deleteReport = (id) => {
    const tid = toast.loading('Deleting, please wait . . .');
    ApiService.delete(`reports/${id}`).then(() => {
        toast.update(tid, {
            render: "Deleted report successfully.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        fetchData(currentPage.value);
    }).catch(() => {
        toast.update(tid, {
            render: "Error deleting report.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const clearDate = (field) => {
    filters.value[field] = '';
    dates.value[field] = '';
    filterList();
}

const convertDate = (dateString) => {
    if (dateString == null) {
        return;
    }
    const myanmarDate = new Date(dateString);
    const year = myanmarDate.getFullYear();
    const month = myanmarDate.getMonth() + 1;
    const day = myanmarDate.getDate();
    filters.value.report_month = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    // if (field == 'activate') {
    //     filters.value.activate = `${month < 10 ? '0' : ''}${month}-${year}`;
    // } else {
    //     const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    //     filters.value[field] = formattedDate;
    // }
    filterList();
}

const onPaginate = (e) => {
    currentPage.value = e.page + 1;
    router.push({name : 'ReportsView' , query : {page : currentPage.value , perPage : perPage.value , ...filterList.value}})
    fetchData(currentPage.value)
}

const changePerPage = (e) => {
    perPage.value = e;
    router.push({name : 'ReportsView' , query : {page : currentPage.value , perPage : perPage.value , ...filterList.value}})
    fetchData(currentPage.value)
}

const filterList = async () => {
    router.push({name : 'ReportsView' , query : {...filters.value , page : currentPage.value , perPage : perPage.value}})
    return fetchData(currentPage.value);
}

onMounted(() => {
    let list = Cookies.get('report_filter');
    if (list) {
        filters.value = JSON.parse(list);
        perPage.value = filters.value.perPage;
    }
    filterList();
    getCategories(1 , "all").then((res) => {
        categories.value = res.data;
    })
    getSubCategories(1 , "all").then((res) => {
        subCategories.value = res.data;
    })
    getStatuses(1 , "all").then((res) => {
        statuses.value = res.data;
    })
})


</script>

<style scoped>

</style>