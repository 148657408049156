<template>
    <div>
        <LoadingIndi v-if="loading" />
        <DataTable v-else :value="sources" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-between">
                    <h1 class="text-2xl font-normal">{{ $t('titles.sources') }}</h1>
                    <Button @click="createForm = true" class="text-white border-0 bg-secondary" :label="$t('actions.create')" size="small" />
                </div>
            </template>
            <Column field="id" :header="$t('labels.id')"></Column>
            <Column field="name" :header="$t('labels.name')"></Column>
            <Column field="custom" :header="$t('labels.actions')">
                <template #body="slotProps">
                    <div class="relative flex gap-2 text-white">
                        <button @click="showEditForm(slotProps.data.id)" class="flex items-center justify-center p-2 text-sm rounded-full bg-secondary">
                            <span style="font-size: 1.2rem;" class="material-icons-round">edit</span>
                        </button>
                        <button @click="deleteSource(slotProps.data.id)" class="flex items-center justify-center p-2 text-sm rounded-full bg-danger">
                            <span style="font-size: 1.2rem;" class="material-icons-round">delete</span>
                        </button>
                    </div>
                </template>
            </Column>
            <template #footer>
                <div class="flex justify-end w-full font-bold">
                    <Paginator
                        v-if="meta.total"
                        @page="onPaginate($event)"
                        :rows="meta.perPage"
                        v-model:first="meta.from"
                        :current-page-report-template="meta.current_page"
                        :totalRecords="meta.total"></Paginator>
                </div>
            </template>
        </DataTable>

        <!-- Create Form -->
        <Dialog v-model:visible="createForm" modal header="Create Source" :style="{ width: '30rem' }">
            <CreateSource @created="refreshData" />
        </Dialog>

        <!-- Edit Form -->
        <Dialog v-model:visible="editForm" modal header="Edit Source" :style="{ width: '30rem' }">
            <EditSource :id="editId" @edited="refreshData" />
        </Dialog>
    </div>
</template>

<script setup>
import { ref , onMounted , watch } from 'vue';
import { useRoute , useRouter } from 'vue-router';
import { getSources } from '@/services/commonApis.js'
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

import CreateSource from '@/components/sources/CreateSource.vue'
import EditSource from '@/components/sources/EditSource.vue';

//primevue component imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Paginator from 'primevue/paginator';
import LoadingIndi from '@/components/LoadingIndi.vue';

const route = useRoute();
const router = useRouter();
const currentPage = ref(+route.query.page);
const loading = ref(true);
const sources = ref([]);
const meta = ref({});
const createForm = ref(false);
const editForm = ref(false);
const editId = ref(0);

watch(currentPage, async (newPage) => {
    fetchData(newPage)
})

const fetchData = (page) => {
    loading.value = true;
    getSources(page).then((res) => {
        loading.value = false;
        sources.value = res.data.data;
        meta.value = res.meta;
        meta.value.total = Number(meta.value.total)
        meta.value.from = Number(meta.value.from) - 1;
    }).catch(() => {
        loading.value = false;
    })
}

const onPaginate = (e) => {
    currentPage.value = e.page + 1;
    router.push({name : 'SourcesView' , query : {page : currentPage.value}})
}

const refreshData = async () => {
    createForm.value = false;
    editForm.value = false;
    fetchData(currentPage.value)
}

const showEditForm = (id) => {
    editId.value = id;
    editForm.value = true;
}

const deleteSource = (id) => {
    const tid = toast.loading('Deleting, please wait . . .');
    ApiService.delete(`sources/${id}`).then(async () => {
        toast.update(tid, {
            render: "Deleted source.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        await refreshData();
    }).catch(() => {
        toast.update(tid, {
            render: "Error deleting source.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

onMounted(async () => {
    fetchData(currentPage.value);
})

</script>

<style scoped>

</style>