import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'vue3-toastify';

const instance = axios.create({
  baseURL: `https://order.ooak.jp/v1/api/`,
  // baseURL: `http://localhost:8000/api/`,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization : `Bearer ${Cookies.get('token')}`,
  },
});

const responseBody = (response) => response.data;

const ApiService = {
  get: (url, body) => instance.get(url, body).then(responseBody).catch((res) => {
    res.response?.data?.message ? toast.error(res.response.data?.message) : toast.error("Error fetching data")
  }),

  post: (url, body, headers) => instance.post(url, body, {headers : headers}).then(responseBody),

  put: (url, body) => instance.put(url, body).then(responseBody),

  delete: (url) => instance.delete(url).then(responseBody),
};

export default ApiService;
