<template>
    <div>
        <div v-if="!addingNewType" class="flex justify-end mb-6">
            <button @click="addingNewType = true" class="px-3 py-1.5 rounded bg-primary text-white">
                add type
            </button>
        </div>
        <form @submit.prevent="createType" v-if="addingNewType" class="flex items-center gap-4 p-4 mb-6 border border-gray-300 rounded">
            <InputText 
                v-model="newType.name"
                placeholder="new product type"
                class="flex-grow h-10 border-success"
            />
            <span @click="addingNewType = false" class="flex items-center justify-center w-10 h-10 text-white rounded cursor-pointer bg-danger">
                <span style="font-size: 1.2rem;" class="material-icons-round">cancel</span>
            </span>
            <button class="flex items-center justify-center w-10 h-10 text-white rounded bg-success">
                <span style="font-size: 1.2rem;" class="material-icons-round">done</span>
            </button>
        </form>
        <div v-if="loading">
            <LoadingIndi />
        </div>
        <ul v-else-if="productTypes.length">
            <li class="flex items-center justify-between my-2" v-for="pType,index in productTypes" :key="pType.id">
                <span class="w-6 mr-1">{{ index + 1 }}.</span>

                <InputText
                    v-if="isEditingType && editingType.id == pType.id"
                    v-model="editingType.name"
                    :id="pType.id"
                    class="flex h-10 rounded-none rounded-l-lg grow"
                />
                <p v-else style="border: 1px solid gray;" class="flex items-center flex-grow h-10 pl-2 rounded-none rounded-l-lg">{{ pType.name }}</p>
                <button title="Cancel" v-if="editingType.id == pType.id" @click="cancelEdit" class="flex items-center justify-center w-10 h-10 text-sm text-white bg-success">
                    <span style="font-size: 1.2rem;" class="material-icons-round">cancel</span>
                </button>
                <button title="Update" v-if="editingType.id == pType.id" @click="updateType" class="flex items-center justify-center w-10 h-10 text-sm text-white rounded-r-lg bg-success">
                    <span style="font-size: 1.2rem;" class="material-icons-round">done</span>
                </button>
                <button title="Edit" @click="editType(pType)" v-else class="flex items-center justify-center w-10 h-10 text-sm text-white rounded-r-lg bg-secondary">
                    <span style="font-size: 1.2rem;" class="material-icons-round">edit</span>
                </button>
                <button title="Edit" @click="deleteType(pType.id)" v-if="editingType.id != pType.id" class="flex items-center justify-center w-10 h-10 ml-4 text-sm text-white rounded-lg bg-danger">
                    <span style="font-size: 1.2rem;" class="material-icons-round">delete</span>
                </button>
            </li>
        </ul>
        <p v-else class="py-12 text-center">
            There is no type for this product.
        </p>
    </div>
</template>

<script setup>
import { defineProps, nextTick, onMounted, ref } from 'vue';

import InputText from 'primevue/inputtext';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';
import LoadingIndi from '@/components/LoadingIndi.vue';


const props = defineProps({
    productId : Number
})

const productTypes = ref([]);
const loading = ref(false);
const addingNewType = ref(false);
const newType = ref({
    name : '',
    product_id : props.productId
})

const isEditingType = ref(false);
const editingType = ref({});

const editType = (t) => {
    isEditingType.value = true;
    editingType.value.name = t.name;
    editingType.value.id = t.id;
    editingType.value.product_id = t.product_id;
    nextTick(() => {
        let inp = document.getElementById(t.id)
        inp.focus();
    })
}

const cancelEdit = () => {
    isEditingType.value = false;
    editingType.value = {};
}

const updateType = () => {
    const id = toast.loading('Updating, please wait . . .');
    ApiService.put(`product-types/${editingType.value.id}` , editingType.value).then(() => {
        toast.update(id, {
            render: "Updated product type.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        getTypes();
        isEditingType.value = false;
        editingType.value = {};
    }).catch(() => {
        toast.update(id, {
            render: "Error updating product type.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const deleteType = (id) => {
    const toastId = toast.loading('Deleting, please wait . . .');
    ApiService.delete(`product-types/${id}`).then(() => {
        toast.update(toastId, {
            render: "Deleted product type.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        getTypes();
    }).catch(() => {
        toast.update(toastId, {
            render: "Error deleting product product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const getTypes = () => {
    loading.value = true;
    ApiService.get(`product-types?product_id=${props.productId}`).then((res) => {
        loading.value = false;
        productTypes.value = res.data;
    }).catch(() => {
        toast.error("Error fetching data.");
        loading.value = false;
    })
}

const createType = () => {
    const id = toast.loading('Creating, please wait . . .');
    ApiService.post('product-types' , newType.value).then(() => {
        toast.update(id, {
            render: "Created product type.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        getTypes();
        addingNewType.value = false;
        newType.value.name = "";
    }).catch(() => {
        toast.update(id, {
            render: "Error creating product type.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

onMounted(() => {
    getTypes();
})

</script>

<style scoped>

</style>