<template>
    <form @submit.prevent="editAcceptTime">
        <div>
            <label class="block mb-1" for="from">From</label>
            <Calendar id="calendar-timeonly" class="w-full" v-model="from" timeOnly />
        </div>
        <div class="mt-3">
            <label class="block mb-1" for="to">To</label>
            <Calendar id="calendar-timeonly" class="w-full" v-model="to" timeOnly />
            <p v-if="errors.name" class="text-sm text-danger">Please fill the required fields.</p>          
        </div>
        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">{{ $t('actions.update') }}</button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits , defineProps, onMounted } from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

//prime vue components
import Calendar from 'primevue/calendar';

const props = defineProps({
    id : Number
})
const emit = defineEmits(['edited']);

const from = ref("");
const to = ref("");
const errors = ref({});

const convertDate = (dateString) => {
    const time = new Date(dateString);
    const format = time.getHours();
    return format < 10 ? `0${format}` : format.toString();
}

const editAcceptTime = () => {
    const id = toast.loading('Updating, please wait . . .');
    let start = convertDate(from.value)
    let end = convertDate(to.value)
    let formData = {
        name : `${start}-${end}`
    }
    ApiService.put(`delivery-accept-times/${props.id}` , formData).then(() => {
        toast.update(id, {
            render: "Updated accept time.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('edited');
    }).catch((res) => {
        toast.update(id, {
            render: "Error updating accept time.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}

onMounted(() => {
    ApiService.get(`delivery-accept-times/${props.id}`).then((res) => {
        let start = res.data.name.split("-")[0];
        let end = res.data.name.split("-")[1];
        from.value = new Date(1970, 0, 1, start, 0, 0, 0)
        to.value = new Date(1970, 0, 1, end, 0, 0, 0)
        // from.value = `${start}:00`
        // to.value = `${end}:00`
    }).catch(() => {
        toast.error("Error fetching data.");
    })
})
</script>

<style scoped>

</style>