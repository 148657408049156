<template>
    <form @submit.prevent="createProduct">
        <div>
            <label class="block mb-1" for="name">{{ $t('labels.name') }}</label>
            <InputText
                v-model="product.name"
                name="name"
                type="text"
                placeholder="product name"
                class="w-full"
                />      
                <p v-if="errors.name" class="text-sm text-danger">{{ errors.name[0] }}</p>          
        </div>
        <div class="mt-4">
            <Checkbox
                binary
                v-model="product.hide" />
            <label class="ml-2" for="active">Hide product from affiliates</label>
        </div>
        <div>
            <FileUpload :initial="{}" @upload="getImageId" />
        </div>
        <div class="flex justify-end mt-6">
            <button class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">{{ $t('actions.create') }}</button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits} from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';
import FileUpload from '@/components/FileUpload.vue'

//prime vue components
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';

const emit = defineEmits(['created']);

const product = ref({
    name : '',
    image_id : null,
    hide : false
})
const errors = ref({});

const getImageId = (id) => {
    product.value.image_id = id;
}

const createProduct = () => {
    const id = toast.loading('Creating, please wait . . .');
    ApiService.post('products' , product.value).then(() => {
        toast.update(id, {
            render: "Created product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('created');
    }).catch((res) => {
        toast.update(id, {
            render: "Error creating product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}
</script>

<style scoped>

</style>