<template>
    <div class="flex flex-col bg-[#BAE3F0] items-center w-screen h-screen p-1">
        <img class="w-5/6 mx-auto md:w-2/5 xl:w-1/3 md:mx-0 h-fit" src="@/assets/images/notfound.png" alt="404">
        <h1 class="mb-4 text-3xl font-semibold text-center text-primary">The page you are looking for cannot be found.</h1>
        <router-link :to="{name : 'OrdersView' , query : {page : 1 , perPage : 10}}" class="px-6 py-3 text-white rounded bg-primary">Go back to Dashboard</router-link>
    </div>
</template>

<script>

</script>

<style scoped>

</style>